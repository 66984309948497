<template>
    <div>
        <TableLayout
            :module="module"
            ref="TableLayout"
            :form-initial="form_initial"
            :form-rules="formRules"
            :rights="rights"
            :table-columns="tableColumns"
            v-if="(user && user.rights) && loaded"
            initSortField="first_name"
            initSortOrder="asc"
            :filters="filters"
            addButtonName="Add Client"
            :additionalOperButtonsWidth="40"
            @cell-click="openPortfolioViaRow"
            no-table-del>

            <template v-slot:form_items="{formdata}">
                <el-form-item label="First Name" prop="first_name">
                    <el-input v-model="formdata.first_name" minlength="1" />
                </el-form-item>
                <el-form-item label="Last Name" prop="last_name">
                    <el-input v-model="formdata.last_name" minlength="1" />
                </el-form-item>
                <el-form-item label="Analysts" prop="users" v-if="can_get_users">
                    <el-select v-model="formdata.users" multiple>
                        <el-option
                            v-for="item in users"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <div class="tl-form-row">
                    <el-form-item label="Company" prop="company">
                        <el-input v-model="formdata.company" />
                    </el-form-item>
                    <el-form-item label="Email" prop="email">
                        <el-input v-model="formdata.email" />
                    </el-form-item>
                    <el-form-item label="Phone" prop="phone">
                        <el-input v-model="formdata.phone" @keypress.native="isNumber($event)" />
                    </el-form-item>
                </div>
                
                <el-form-item label="Description" prop="description">
                    <el-input v-model="formdata.description" />
                </el-form-item>

                <div v-for="(field, index) in formdata.fields" class="custom_fields-row">
                    <el-form-item label="Custom Field Name" prop="phone">
                        <el-input v-model="field.field_name" />
                    </el-form-item>
                    <el-form-item label="Custom Field Value" prop="phone" class="spacer">
                        <el-input v-model="field.field_value" />
                    </el-form-item>
                    <div class="custom_fields-row_del">
                        <el-button type="text" size="mini" @click="formdata.fields.splice(index, 1);">
                            <i class="el-icon-close"></i>
                        </el-button>
                    </div>
                </div>
                <div>
                    <el-button type="text" size="mini" @click="formdata.fields.push({field_name:'', field_value:''})"><i class="el-icon-plus"></i> custom field</el-button>
                </div>
            </template>

            <template v-slot:additional_oper_buttons="{row}">
                <el-button
                    type="text"
                    @click="openPortfolio(row)"
                    size="mini"
                    class="tl-oper_button">
                    <svg-icon icon-class="portfolio" />
                </el-button>
            </template>

        </TableLayout>
    </div>
</template>

<script>

import { mapState } from 'vuex'
import TableLayout from '@/components/TableLayout'
import tab from '@/mixins/tab'

export default {
    components: { TableLayout },
    mixins: [tab],

    data() {
        return {
            module: 'clients',
            loaded: false,
            formRules: {
                first_name: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                last_name: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                users: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                phone: [
                    {trigger: 'blur', validator (rule, value, callback) {
                        if (!value || /[\d]/.test(value)) {
                            callback();
                        } else {
                            callback(new Error('Phone number must have digits only'));
                        }
                    }}
                ],
                email: [
                    {trigger: 'blur', validator (rule, value, callback) {
                        if (!value || /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/.test(value)) {
                            callback();
                        } else {
                            callback(new Error('Not valid email'));
                        }
                    }}
                ]
            },
            filters: {
                first_name  : { value: undefined },
                last_name   : { value: undefined },
                email       : { value: undefined },
                phone       : { value: undefined },
                company     : { value: undefined },
                description : { value: undefined },
                users       : { value: undefined, strict: true, },
            }
        }
    },

    computed: {
        ...mapState({
            user         : state => state.app.user,
            users        : state => state.users.options,
            clients_list : state => state.clients.list,
        }),

        form_initial() {
            return {
                fields : [],
                users  : [this.user.id]
            }
        },

        currentClient: {
            get() { return this.$store.state.portfolio.currentClient; },
            set(value) { this.$store.commit('portfolio/set', {type: 'currentClient', items:value}); },
        },

        tableColumns(){
            let columns = [
                {
                    field  : 'first_name', 
                    title  : 'First Name', 
                    width  : 140, 
                    fixed  : 'left',
                    params : {
                        filter : 'input', 
                    }, 
                },{
                    field    : 'last_name', 
                    title    : 'Last Name', 
                    minWidth : 200, 
                    fixed    : 'left',
                    params : {
                        filter : 'input', 
                    },
                },{
                    field  : 'email', 
                    title  : 'Email', 
                    width  : 200, 
                    params : {
                        filter : 'input', 
                    },
                },{
                    field  : 'phone', 
                    title  : 'Phone', 
                    width  : 120, 
                    params : {
                        filter : 'input', 
                    },
                },{
                    field  : 'company', 
                    title  : 'Company', 
                    width  : 150, 
                    params : {
                        filter : 'input', 
                    },
                },{
                    field    : 'description', 
                    title    : 'Description', 
                    minWidth : 200, 
                    params : {
                        filter : 'input', 
                    },
                },
            ]

            if (this.can_get_users)
                columns.push({
                    field: 'users', 
                    title : 'Analysts', 
                    minWidth: 200,  
                    params : {
                        filter  : 'select', 
                        options : this.users,
                        editor  : 'select', 
                    },
                })

            return columns
        },

        rights(){
            return {
                view : (this.user && this.user.rights) ? this.user.rights['get-clients']   : false,
                edit : (this.user && this.user.rights) ? this.user.rights['edit-clients']  : false,
                add  : (this.user && this.user.rights) ? this.user.rights['add-clients']   : false,
                del  : (this.user && this.user.rights) ? this.user.rights['delete-clients']: false,
            }
        },

        can_get_users(){
            return (this.user && this.user.rights) ? this.user.rights['get-users'] : false;
        }
    },

    methods: {
        openPortfolioViaRow(event){
            let operation_buttons_column_id = 8
            if(event.$columnIndex !== operation_buttons_column_id) this.openPortfolio(event.row)
        },
        openPortfolio(row){
            this.$router.push('/client/' + row.id)
            // let index = this.clients_list.findIndex(x => x._id === row['_id']);
            // this.$store.commit('portfolio/set', {type: 'currentClient', items:this.clients_list[index]});
            // this.$router.push({ path: 'portfolio' })
        },

        isNumber(evt){
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        }
    },

    created(){
        if (this.can_get_users)
            this.$store.dispatch('users/getOptions').then(() => {
                this.loaded = true
            });
        else
            this.loaded = true
    },
}
</script>
